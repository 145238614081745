import TYPES from '@/types';

// Domain
import {
  CustomerPersonalInformationStateManager,
} from '@/modules/customer/personal-information/domain/state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Router } from '@/modules/shared/domain/router';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class IdentityDocumentUpdatedConfirmationViewModel {
  @Inject(TYPES.CUSTOMER_PERSONAL_INFORMATION_STATE_MANAGER)
  private readonly personal_information_state_manager!: CustomerPersonalInformationStateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.ROUTER)
  readonly router!: Router;

  readonly i18n_namespace = 'components.update-identity-document.confirmation';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToLogin = (authenticated_customer: boolean) => {
    if (!authenticated_customer) {
      sessionStorage.clear();
      this.router.navigate('/login');
    } else {
      window.location.href = '/';
    }
  }
}
