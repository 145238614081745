









































import { Component, PropSync, Vue } from 'vue-property-decorator';
import IdentityDocumentUpdatedConfirmationViewModel
  from '@/vue-app/view-models/components/update-identity-document/identity-document-updated-confirmation-view-model';

@Component({ name: 'IdentityDocumentUpdatedConfirmation' })
export default class IdentityDocumentUpdatedConfirmation extends Vue {
  @PropSync('authenticatedCustomer', { type: Boolean, required: true })
  synced_authenticated_customer!: boolean;

  view_model = Vue.observable(new IdentityDocumentUpdatedConfirmationViewModel());
}

